import { useTranslate } from '../../lib/hooks/useTranslate';
import { ITranslateFunction } from '../../model/translate';
import { TWarningSeverity } from '../../model/warning';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';
import './ExpandableWarningHeading.scss';

interface IProps {
  title: string;
  severity: TWarningSeverity;
  label?: string;
}

export function ExpandableWarningHeading(props: IProps) {
  const { title, severity, label } = props;

  const translate = useTranslate();

  const subtitle = getSubtitle(severity, translate);

  return (
    <div className="warning-heading" data-severity={severity}>
      <span className="warning-heading__title-wrapper" role="text">
        <span className="nrk-sr">{translate('warning/accessibleLabel')} </span>

        <Heading level="h3" size="4" className="warning-heading__title">
          {title}
        </Heading>

        {label && (
          <Text size="5" className="warning-heading__label">
            {label}
          </Text>
        )}
      </span>
      <Text size="5" className="warning-heading__subtitle">
        {subtitle}
      </Text>
    </div>
  );
}

function getSubtitle(severity: TWarningSeverity, translate: ITranslateFunction) {
  return translate('warning/severityLabel', {
    severity: translate(`warning/enums/severity/${severity}`)
  });
}
