import classNames from 'classnames';
import './WarningTimeline.scss';

interface IProps {
  type: 'start' | 'start-with-open-end' | 'end';
  text: string;
}

export function WarningTimeline__Item(props: IProps) {
  const { type, text } = props;

  return (
    <li
      className={classNames('warning-timeline__item', {
        'warning-timeline__item--with-open-end': type === 'start-with-open-end'
      })}
      data-testid={`warning-timeline__item-${type}`}
    >
      <div className="warning-timeline__circle"></div>
      {text}
      {type === 'start-with-open-end' && <div className="warning-timeline__ending-arrow"></div>}
    </li>
  );
}
