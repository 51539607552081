import { ExpandableWarning } from './ExpandableWarning';
import { IWarning } from '../../model/warning';

export interface IProps {
  warnings: IWarning[];
  showAreaInHeading?: boolean;
}

export function ExpandableWarningList(props: IProps) {
  const { warnings, showAreaInHeading = false } = props;

  return (
    <ol className="warnings">
      {warnings.map((warning, index) =>
        warning ? <ExpandableWarning key={index} warning={warning} showAreaInHeading={showAreaInHeading} /> : null
      )}
    </ol>
  );
}
