import { Icon } from '@nrk/yr-icons';
import { IWarningContentDangerLevel } from '../../model/warning';
import './ExpandableWarningSeverity.scss';

interface IProps {
  dangerLevels: IWarningContentDangerLevel[];
}

export function ExpandableWarningSeverity(props: IProps) {
  const { dangerLevels } = props;

  const icon = (
    <span aria-hidden="true" className="warning-severity__icon">
      <Icon id="icon-check" size={2} />
    </span>
  );

  return (
    <div className="warning-severity" data-testid="expandable-warning-severity">
      {dangerLevels.map(dangerLevel => (
        <div
          key={dangerLevel.severity}
          className="warning-severity__box-wrapper"
          aria-hidden={!dangerLevel.selected}
          data-testid={`expandable-warning-severity/${dangerLevel.severity}`}
        >
          <div className="warning-severity__box" data-severity={dangerLevel.severity}>
            {dangerLevel.selected && icon}
          </div>
          <span className="warning-severity__description" data-disabled={dangerLevel.disabled}>
            {dangerLevel.description}
          </span>
        </div>
      ))}
    </div>
  );
}
