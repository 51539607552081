import CoreToggle from '@nrk/core-toggle/jsx';
import { Icon } from '@nrk/yr-icons';
import { useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { track } from '../../lib/analytics/track';
import { IWarning, IWarningContentImage } from '../../model/warning';
import { ClickableButton } from '../Clickable/Clickable';
import { Heading } from '../Heading/Heading';
import { WarningIcon } from '../WarningIcon/WarningIcon';
import { WarningImageDialog } from '../WarningImageDialog/WarningImageDialog';
import { WarningTimeline } from '../WarningTimeline/WarningTimeline';
import './ExpandableWarning.scss';
import { ExpandableWarningHeading } from './ExpandableWarningHeading';
import { ExpandableWarningLink } from './ExpandableWarningLink';
import { ExpandableWarningSeverity } from './ExpandableWarningSeverity';

interface IProps {
  warning: IWarning;
  showAreaInHeading: boolean;
}

export function ExpandableWarning(props: IProps) {
  const { warning, showAreaInHeading } = props;
  const { meta, content } = warning;
  const { shortTitle, longTitle, eventType, severity, label } = meta;
  const { currentPage } = useAppState();

  const { embedded } = currentPage.details.query;

  const [expanded, setExpanded] = useState(false);
  const [warningImage, setWarningImage] = useState<IWarningContentImage>();

  function onWarningToggle(event: Event) {
    event.preventDefault();

    setExpanded(prevExpanded => {
      if (embedded === false) {
        track.event({ category: 'warning', action: prevExpanded ? 'collapse' : 'expand' });
      }

      return !prevExpanded;
    });
  }

  function onWarningImageClose() {
    setWarningImage(undefined);
  }

  return (
    <li className={'warning'} data-expanded={expanded} data-severity={meta.severity} data-testid="expandable-warning">
      <ClickableButton className="warning__button">
        <span className="warning__toggle" aria-hidden={true}>
          <Icon id={expanded ? 'icon-chevron-up' : 'icon-chevron-down'} />
        </span>
        <span className="warning__icon" aria-hidden={true}>
          <WarningIcon title={shortTitle} eventType={eventType} severity={severity} size={4} />
        </span>
        <ExpandableWarningHeading
          title={showAreaInHeading ? longTitle : shortTitle}
          label={label}
          severity={severity}
        />
      </ClickableButton>
      <CoreToggle className="warning__body" onToggle={onWarningToggle} hidden={!expanded}>
        <div className="warning__content">
          {content.map((item, index) => {
            switch (item.type) {
              case 'heading-2':
                return (
                  <Heading
                    level="h4"
                    size="4"
                    key={index}
                    className="warning__content-heading"
                    data-testid="warning__content-heading"
                  >
                    {item.text}
                  </Heading>
                );
              case 'text':
                return (
                  <p data-testid="warning__text" key={index}>
                    {item.text}
                  </p>
                );
              case 'timeline':
                return <WarningTimeline key={index} items={item.items} />;
              case 'image':
                return (
                  <div key={index} className="warning__image-column">
                    <ClickableButton
                      className="warning__image-button"
                      onClick={() => {
                        setWarningImage(item);
                      }}
                    >
                      <img
                        alt={item.description}
                        data-testid="warning__image"
                        className="warning__image"
                        src={item.url}
                      />
                    </ClickableButton>
                  </div>
                );
              case 'link':
                return <ExpandableWarningLink key={index} {...item} />;
              case 'list':
                return (
                  <ul key={index} data-testid="warning__content-list" className="warning__content-list">
                    {item.items.map((listItem, index) => (
                      <li key={index}>{listItem.text}</li>
                    ))}
                  </ul>
                );
              case 'severity':
                return (
                  <div data-testid="warning__severity" key={index}>
                    <ExpandableWarningSeverity dangerLevels={item.dangerLevels} />
                    <ExpandableWarningLink {...item.helpText} />
                  </div>
                );
              default:
                return undefined;
            }
          })}
        </div>
      </CoreToggle>
      {warningImage && (
        <WarningImageDialog
          description={warningImage.description}
          onClose={onWarningImageClose}
          url={warningImage.url}
        />
      )}
    </li>
  );
}
