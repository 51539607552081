import { IWarningContentLink } from '../../model/warning';

export function ExpandableWarningLink(props: IWarningContentLink) {
  const { text, url, linkText } = props;

  const textSplitByLinkText = text.split(linkText);

  // There should be exactly one occurence of `linkText` in `text`, meaning there should allways be exactly two entries in this array
  if (textSplitByLinkText.length !== 2) {
    return null;
  }

  return (
    <div className="warning__link-text">
      {textSplitByLinkText[0]}
      <a className="warning__link" href={url}>
        {linkText}
      </a>
      {textSplitByLinkText[1]}
    </div>
  );
}
