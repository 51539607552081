import { ModalDialog } from '../ModalDialog/ModalDialog';
import './WarningImageDialog.scss';

interface IProps {
  description: string;
  url: string;
  onClose: () => void;
}

export function WarningImageDialog(props: IProps) {
  const { description, url, onClose } = props;

  return (
    <ModalDialog renderOnServer={false} onClose={onClose}>
      <div className="warning-image-dialog">
        <img
          alt={description}
          className="warning-image-dialog__image"
          data-testid="warning-image-dialog-image"
          src={url}
        />
      </div>
    </ModalDialog>
  );
}
