import './WarningTimeline.scss';
import { WarningTimeline__Item } from './WarningTimeline__Item';

export interface IProps {
  items: [{ type: 'text'; text: string }, { type: 'text'; text: string }?];
}

export function WarningTimeline(props: IProps) {
  const { items } = props;

  return (
    <ol className="warning-timeline" data-testid="warning-timeline">
      <WarningTimeline__Item type={items[1] != null ? 'start' : 'start-with-open-end'} text={items[0].text} />

      {items[1] != null && <WarningTimeline__Item type="end" text={items[1].text} />}
    </ol>
  );
}
